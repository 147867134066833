const MAX_BREEDER_TOTAL = {
  NOK: 10000,
  DKK: 8000,
  USD: 800,
  EUR: 800,
  GBP: 800,
  SEK: 10000,
  CZK: 20000,
  CHF: 800,
};

export function getMaxBreederTotal(currency) {
  const maxBreederTotal = MAX_BREEDER_TOTAL[currency];

  if (maxBreederTotal === undefined) {
    throw new Error(`No max breeder total found for currency: ${currency}`);
  }

  return maxBreederTotal;
}
