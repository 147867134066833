export * from './redirect';

export const parseCategory = (categoryName) => {
    return categoryName?.split(' ').join('_')
}

export const reverseParseCategory = (categoryName) => {
    return categoryName?.split('_').join(' ')

}

export const parseProduct = (productName) => {
    return productName?.split(' ').join('_')
}