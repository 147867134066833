"use client"
import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Modal from "react-modal";
import useStore from "../../lib/apollo/storage/appConfig/useStore";
import useUser from "../../lib/apollo/storage/appConfig/useUser";
import { getTermsAndConditionsLink } from "../../utils/getTermsAndConditionsLink";
import { useRouter } from "next/navigation";
import useActiveOrder from "../../lib/apollo/storage/appConfig/useActiveOrder";
import { ACCEPT_TERMS_AND_CONDITIONS_MUTATION, SEND_EMAIL_TO_MANAGERS } from "../../lib/apollo";
import { findManagerEmails } from "../../utils/findManagerEmail";
import { storeVar } from "@apollo-custom/storage/appConfig/apolloVars";
import { ErrorMessage, ErrorText, ModalContainer, ModalContent, ModalHeader, CancelButton, ModalLink, AgreementText, ButtonContainer, AcceptButton, ErrorEmails, CenteredDiv, StyledButton } from "./styles";

const TermsAndConditionsModal = ({ isOpen, onRequestClose }) => {


  const { id: orderId } = useActiveOrder();

  const { id, country, users, termsAndConditionsAccepted, addStore } = useStore();
  const [accepted, setAccepted] = useState(termsAndConditionsAccepted);

  const { id: userId, userType } = useUser();

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [acceptTermsAndConditions, { data, loading, error }] = useMutation(
    ACCEPT_TERMS_AND_CONDITIONS_MUTATION
  );
  const [sendEmailToManagers, { data: dataSendEmail }] =
    useMutation(SEND_EMAIL_TO_MANAGERS);

  const router = useRouter();

  const handleClick = () => {

    acceptTermsAndConditions({
      variables: { userId: userId, storeId: id },
    })
      .then(() => {
        addStore({
          ...storeVar(),
          termsAndConditionsAccepted: true,
        });

        onRequestClose();
        setAccepted(true);

        router.push(`/checkout?orderId=${orderId}`)


      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSendEmails = () => {
    setIsButtonClicked(true);
    sendEmailToManagers({ variables: { storeId: id } });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            zIndex: 1000,
          },
          content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            maxHeight: "60vh",
            width: "auto",
            maxWidth: "45vw",
            overflow: "scroll",
          },
        }}
        contentLabel="Terms and Conditions"
      >
        <ModalContainer>
          <ModalContent>
            <ModalHeader>Non-Stop Dogwear Terms &amp; Conditions</ModalHeader>
            <AgreementText>
              By clicking 'Accept Terms', you confirm that you have read and
              understood the terms and conditions of Non-stop dogwear and agree to
              abide by them.
            </AgreementText>
            <ModalLink
              href={getTermsAndConditionsLink(country)}
              target="_blank"
              rel="noopener noreferrer"
            >
              View the full Terms and Conditions (PDF)
            </ModalLink>

            <ButtonContainer>
              <AcceptButton
                onClick={() => {
                  handleClick();
                }}
                disabled={accepted || userType !== "MANAGER"}
              >
                Accept Terms
              </AcceptButton>
              <CancelButton onClick={onRequestClose}>Cancel</CancelButton>
            </ButtonContainer>
            <CenteredDiv>
              {!accepted && userType !== "MANAGER" && (
                <>
                  <ErrorMessage>
                    <ErrorText>
                      Only managers can accept terms and conditions. Please log in
                      with a manager account.
                    </ErrorText>
                    {findManagerEmails(userType, users) &&
                      findManagerEmails(userType, users).length > 0 && (
                        <ErrorEmails>
                          {findManagerEmails(userType, users).join(", ")}
                        </ErrorEmails>
                      )}
                  </ErrorMessage>
                  <StyledButton
                    onClick={handleSendEmails}
                    isClicked={isButtonClicked}
                    disabled={isButtonClicked}
                    title={isButtonClicked ? "Clicked" : "Notify the manager"}
                  >
                    {isButtonClicked ? "Notified" : "Notify the manager"}
                  </StyledButton></>
              )}

            </CenteredDiv>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default TermsAndConditionsModal;
