"use client"
import styled from 'styled-components';
import { device } from '../../utils/deviceSizes';

export const TitleStyles = styled.h1`
  display: flex;
  align-items: center;

  width: 100%;
  
  /* color: var(--primary); */
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 7rem;
  margin-bottom: 2rem;
  color: #fff;

  @media ${device.tablet} {
    margin: 1rem 2rem;
    width: calc(100% - 4rem);
    overflow: hidden;
  }

  .title {
    min-width: fit-content;
    margin-right: 1.5rem;
  }
  .separator {
    height: 1px;
    width: 100%;
    display: inline-block;
    background: grey;
  }
`;

export const ToolTipStyles = styled.div`
  padding: 0.5rem;
  position: absolute;
  width: 22.5rem;
  top: -3rem;
  background: var(--primary);
  color: #fff;
  cursor: pointer;

  .tooltip {
    &:after {
      content: '';
      position: absolute;

      /* position tooltip correctly */
      left: 3.3rem;
      bottom: -1.8rem;
      margin-left: 5px;

      /* vertically center */

      transform: rotate(270deg);

      /* the arrow */
      border: 10px solid #000;
      border-color: transparent var(--primary) transparent transparent;
    }
  }
`;
