"use client";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_CATEGORY_PAGE } from "../../queries";
import { categoryPageVar } from "./apolloVars";
import useWindowSize from "@utils/useWindowSize";

export default function useCategoryPage() {
  const categoryPage = useReactiveVar(categoryPageVar);

  const { width } = useWindowSize();
  const formatedWidth = width || 0;

  return {
    selectedCategory: categoryPage?.selectedCategory,
    selectedProduct: categoryPage?.selectedProduct,
    addCategoryPage(pageData) {
      if (formatedWidth < 768) {
        categoryPageVar({
          selectedCategory: pageData.selectedCategory,
          selectedProduct: pageData.selectedProduct,
        });
      }
    },
  };
}
