"use client"
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_CATEGORY_TARGET } from '../../queries';
import { categoriesVar } from './apolloVars';

export default function useCategories() {
  const cachedCategories = useReactiveVar(categoriesVar);
  return {
    target: cachedCategories?.target,
    categories: cachedCategories?.categories,
    loading: cachedCategories?.loading,
    addCategories({ target, categories: receivedCategories }) {
      categoriesVar({
        target: target !== '' ? target : cachedCategories?.target,
        categories: receivedCategories,
        loading: false,
      });
    },
  };
}
