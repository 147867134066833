import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_CART_OPEN } from '../../queries';
import { cartOpenVar } from './apolloVars';


export default function useCartOpen() {
  const cartOpen = useReactiveVar(cartOpenVar);
  return {
    cartOpen: cartOpen?.cartOpen,
    toggleCart() {
      cartOpenVar({cartOpen: !cartOpen.cartOpen});
    }
  };
}
