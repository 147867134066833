"use client"
import React, { useState } from "react";
import Link from "next/link";

// APOLLO
import useActiveOrder from "../../lib/apollo/storage/appConfig/useActiveOrder";

// COMPONENTS
import ProductWithSkus from "../Ui/Product/ProductWithSkus";
import TitleWithSeparator from "../Ui/TitleWithSeparator";

// UTILS
import { useCart } from "../../utils/cartUtils";
import formatMoney from "../../utils/formatPrice";
import useUser from "../../lib/apollo/storage/appConfig/useUser";

// STYLES
import { CartStyles } from "./styles";
import useStore from "../../lib/apollo/storage/appConfig/useStore";
import useCartOpen from "../../lib/apollo/storage/appConfig/useCartOpen";
import usePreOrder from "../../lib/apollo/storage/appConfig/usePreOrder";
import useCompany from "../../lib/apollo/storage/appConfig/useCompany";
import TermsAndConditionsModal from "../TermsAndConditions";
import { getMaxBreederTotal } from "../../utils/getMaxBreederTotal";
import { FaExclamationCircle } from "react-icons/fa";
import { isEuCountry } from "@utils/isEuCountry";
import { getDiscountedPrice } from '@utils/getDiscountedPrice';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

interface CartProps {
  className: string;
}

const Cart: React.FC<CartProps> = ({ className }) => {
  const cartProps = useCart();
  const { id: orderId, totalVAT, discount, totalPrice, vatRateOrder } = useActiveOrder();
  const { cartOpen, toggleCart } = useCartOpen();
  const { selectedWindow } = usePreOrder();
  const { currency, vatRate } = useCompany();
  const { status } = useStore();
  const { customerType } = useUser();

  const { address, termsAndConditionsAccepted, country } = useStore();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleButton = (e) => {
    if (cartOpen) toggleCart();

    if (!termsAndConditionsAccepted && isEuCountry(country)) {
      e.preventDefault();
      setModalIsOpen(true);
    }

  };

  const t = useTranslations("cart");
  const { locale } = useParams()
  return (
    <CartStyles className={`${className} ${cartOpen ? "open" : ""}`} >
    <div className="overlay" />
    <TitleWithSeparator title={t("yourOrder")} className="order-title" />
    
    <div className="cart-container">
      <div className="cart-container__header">
        <h3>{selectedWindow ? t("preOrder") : t("order")}</h3>
        <button onClick={() => toggleCart()}>{t("goBack")}</button>
      </div>
  
      <ul className="cart-product-list">
        {cartProps?.itemsByProduct?.map((product: any) => {
          return (
            product?.skus?.[0]?.sku !== "0001" && product?.skus?.[0]?.sku !== "TEMPTAX" && (
              <ProductWithSkus
                orderStatus=""
                className="cart-product"
                key={product.productName}
                productName={product.productName}
                skus={product.skus}
                isOrder={false}
                productId={product.productId}
              />
            )
          );
        })}
      </ul>
  
      <hr />
      <h2 className="shipping">
        <span>{t("shipping")}</span>
        <span>
          {formatMoney(
            getDiscountedPrice(cartProps?.shippingCost, true, discount) || 0,
            currency
          )}
        </span>
      </h2>
  
      {totalVAT > 0 && (
        <h2 className="shipping">
          <span>
            {country !== "US"
              ? t("vatAt", { vatRate: vatRate || vatRateOrder })
              : t("salesTax")}
          </span>
          <span>{formatMoney(totalVAT, currency)}</span>
        </h2>
      )}
  
      <hr />
      <h2 className="total">
        <span>{t("total")}</span>
        <p>{formatMoney(totalPrice || 0, currency)}</p>
      </h2>
  
      <p className="shipping-address">{address}</p>
  
      {customerType === "BREEDERS" &&
        cartProps?.totalPrice > getMaxBreederTotal(currency) && (
          <div className="warning-message">
            <div className="message-header">
              <FaExclamationCircle className="warning-icon" />
              <h3>{t("breederLimitExceeded")}</h3>
            </div>
            <p>
              {t("cannotOrderMore", {
                maxAmount: formatMoney(getMaxBreederTotal(currency), currency)
              })}
            </p>
            <p>
              {t("pleaseRemove", {
                amountToRemove: formatMoney(
                  cartProps?.totalPrice - getMaxBreederTotal(currency),
                  currency
                )
              })}
            </p>
          </div>
        )}
  
      {status === "ON_HOLD" && (
        <div className="warning-message warning-message__red">
          <p>{t("onHoldMessage")}</p>
        </div>
      )}
  
      <div className="checkout-container">
        {isEuCountry(country) ? (
          <>
            <Link
              href={
                termsAndConditionsAccepted
                  ? { pathname: `/${locale}/checkout`, query: { orderId } }
                  : "#"
              }
            >
              <button
                disabled={
                  cartProps?.itemsByProduct?.length === 0 ||
                  (customerType === "BREEDERS" &&
                    cartProps?.totalPrice > getMaxBreederTotal(currency))
                }
                className="checkout"
                onClick={handleButton}
              >
                {t("checkout")}
              </button>
            </Link>
            <TermsAndConditionsModal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
            />
          </>
        ) : (
          <Link href={{ pathname: `/${locale}/checkout`, query: { orderId } }}>
            <button
              disabled={
                cartProps?.itemsByProduct?.length === 0 ||
                (customerType === "BREEDERS" &&
                  cartProps?.totalPrice > getMaxBreederTotal(currency))
              }
              className="checkout"
              onClick={handleButton}
            >
              {t("checkout")}
            </button>
          </Link>
        )}
      </div>
    </div>
  </CartStyles>
  
  );
};

export default Cart;
