import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_PRE_ORDER } from "../../queries";
import { preOrderVar } from "./apolloVars";

export default function usePreOrder() {
  const preOrder = useReactiveVar(preOrderVar);
  return {
    selectedWindow: preOrder?.selectedWindow,
    preOrderWindowName: preOrder?.preOrderWindowName,
    selectWindow(id, name) {
      preOrderVar({ selectedWindow: id, preOrderWindowName: name });
    },
  };
}
