import styled from "styled-components";
import { device } from "../../utils/deviceSizes";

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    max-width: 700px;
    height: 700px;
  }

  @media ${device.tablet} {
    max-width: 650px;
    height: 650px;
  }

  @media ${device.mobileL} {
    max-width: 90%;
    height: auto;
  }
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;

  @media ${device.tablet} {
    padding: 15px;
    max-width: 400px;
  }

  @media ${device.mobileL} {
    padding: 10px;
    max-width: 90%;
  }
`;

export const ModalHeader = styled.h2`
  color: #ff4500;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 2em;
  text-align: center;

  @media ${device.tablet} {
    font-size: 1em;
  }

  @media ${device.mobileL} {
    font-size: 0.8em;
  }
`;

export const AgreementText = styled.p`
  color: gray;
  text-align: center;
  margin: 20px;

  @media ${device.tablet} {
    font-size: 0.7em;
    margin: 10px;
    text-align: justify;
  }

  @media ${device.mobileL} {
    font-size: 0.6em;
    margin: 8px;
  }
`;

export const ModalLink = styled.a`
  color: var(--orange);
  text-decoration: none;
  margin: 20px;

  &:hover {
    color: #ff6347;
  }

  @media ${device.tablet} {
    margin: 12px;
    font-size: 0.7em;
    text-align: center;
  }

  @media ${device.mobileL} {
    margin: 10px;
    font-size: 0.6em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const AcceptButton = styled.button`
  background-color: var(--orange);
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;

  @media ${device.tablet} {
    padding: 8px 16px;
    margin-bottom: 10px; 
    margin-right: 0px;

  }

  @media ${device.mobileL} {
    padding: 6px 12px;
  }

  &:disabled {
    background-color: gray;
  }
`;

export const CancelButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;

  @media ${device.tablet} {
    padding: 8px 16px;
    margin-bottom: 10px; 
  }

  @media ${device.mobileL} {
    padding: 6px 12px;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 1em;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;

  @media ${device.tablet} {
    font-size: 0.9em;
    margin-top: 15px;
  }

  @media ${device.mobileL} {
    font-size: 0.8em;
    margin-top: 10px;
  }
`;

export const ErrorText = styled.span`
  color: var(--orange); // Black color
  display: block; // This will put the content in a new line

  @media ${device.tablet} {
    font-size: 0.9em;
  }

  @media ${device.mobileL} {
    font-size: 0.8em;
  }
`;

export const ErrorEmails = styled.span`
  color: #808080; // Gray color
  font-style: italic;
  display: block; // This will put the content in a new line

  @media ${device.tablet} {
    font-size: 0.9em;
  }

  @media ${device.mobileL} {
    font-size: 0.8em;
  }
`;

export const CenteredDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center; /* For centering text within child elements */
`;
interface StyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isClicked: boolean;
}

export const StyledButton = styled.button<{ isClicked: boolean }>`
  /* Basic button styles */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;

  /* Styles when button is clicked */
  background-color: ${props => props.isClicked ? '#6c757d' : 'var(--orange)'};
  pointer-events: ${props => props.isClicked ? 'none' : 'auto'};
  /* Add other styles for the clicked state as needed */
`;
