import React from 'react';
import { TitleStyles } from './style';

const TitleWithSeparator = ({ title, className }) => {
  return (
    <TitleStyles className={className}>
      <span className="title">{title}</span>
      <span className="separator"></span>
    </TitleStyles>
  );
};

export default TitleWithSeparator;
