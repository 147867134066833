import Link from "next/link";
import React from "react";
import slugify from "slugify";

import styled from "styled-components";
import useCategoryPage from '@apollo-custom/storage/appConfig/useCategoryPage';
import { useParams } from 'next/navigation';

const CategoryLinkStyles = styled.li`
  display: none;
  &.show {
    display: block;
  }
`;

interface CategoryLinkProps {
  id: string;
  currentCategory: string;
  name: string;
  slug: string;
  value?: string;
  products: any;
  englishName: string;
  productSlug?: string;
}

const CategoryLink: React.FC<CategoryLinkProps> = ({
  id,
  currentCategory,
  name,
  slug,
  productSlug,
  value,
  englishName
}) => {
  const { addCategoryPage } = useCategoryPage();

  const { locale } = useParams()
  return (
    <CategoryLinkStyles
      key={id}
      className={`${currentCategory.split("-")[0].toLowerCase() === englishName?.split("-")[0].split(" ")[0].toLowerCase()
        ? "active"
        : ""
        }
      show
          `}
    >
      <Link
        href={`/${locale}/category/${slugify(value?.toLowerCase() || englishName?.toLowerCase())}/${productSlug}`}
        onClick={() => addCategoryPage({ selectedCategory: true, selectedProduct: false })}
      >
        {name}
      </Link>
    </CategoryLinkStyles>

  );
};

export default CategoryLink;
