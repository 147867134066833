"use client";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_NOTIFICATIONS } from "../../queries";
import { notificationsVar } from "./apolloVars";

export default function useNotifications() {
  const notifications = useReactiveVar(notificationsVar);
  return {
    notifications: notifications?.notifications,
    addNotifications(notifications) {
      notificationsVar({ notifications });
    },
  };
}
